import React, { useEffect } from "react"
import ReactNotification from "react-notifications-component"
import TagManager from 'react-gtm-module'
import Header from "./header"
import Footer from "./footer"

import "../css/app.scss"

const Layout = ({ children }) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = "https://d335luupugsy2.cloudfront.net/js/loader-scripts/869cb91d-2c98-4dc1-be4c-2777477114cd-loader.js"
    script.async = true
    document.body.appendChild(script)

    TagManager.initialize({ gtmId: "GTM-PVF385Z" })

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div>
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PVF385Z" title="tagmanger" height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>
      </noscript>

      <ReactNotification />
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
