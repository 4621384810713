const Config = {
  BACKEND_HOSTS: ["superbom.com.br/panel"],
  FRONTEND_PROTOCOL: "https",
  FRONTEND_HOST: "superbom.com.br",
  links: {
    address: "https://www.google.com/maps/place/Superbom/@-23.662209,-46.7788717,17z/data=!3m1!4b1!4m5!3m4!1s0x94ce5363e924fb79:0xdd1215d38b9fde3b!8m2!3d-23.662209!4d-46.776683",
    facebook: "https://www.facebook.com/SuperbomBR",
    twitter: "https://twitter.com/superbombr",
    instagram: "https://www.instagram.com/superbombr/",
    youtube: "https://www.youtube.com/channel/UCW29efqzFZnLNoxAceU9Qnw",
    linkedin: "https://www.linkedin.com/company/superbominfra/"
  }
}

export default Config
