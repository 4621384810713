import config from "./config"

export default {
  replaceUrl(url) {
    config.BACKEND_HOSTS.forEach(backendHost => {
      url = url.split('//'+backendHost).join('//'+config.FRONTEND_HOST)
    })
    url = url.split('http://').join(`${config.FRONTEND_PROTOCOL}://`)
    url = url.split('https://').join(`${config.FRONTEND_PROTOCOL}://`)
    return url
  },

  contactPath(categorySlug) {
    return `/fale-conosco/${categorySlug}/`
  },

  blogPath(type) {
    if (type === 'post') {
      return '/blog/'
    } else {
      return '/news/'
    }
  },

  blogCategoryPath(type, slug) {
    if (type === 'post') {
      return `/blog/${slug}/`
    } else {
      return `/news/${slug}/`
    }
  },

  blogTagPath(type, slug) {
    if (type === 'post') {
      return `/blog/tag/${slug}/`
    } else {
      return `/news/tag/${slug}/`
    }
  },

  blogAuthorPath(type, slug) {
    if (type === 'post') {
      return `/blog/author/${slug}/`
    } else {
      return `/news/author/${slug}/`
    }
  },

  blogPostPath(post) {
    if (post.type === 'post' && post.primary_category) {
      const categorySlug = post.primary_category.slug
      return `/blog/${categorySlug}/${post.slug}/`

    } else if (post.type === 'news') {
      return `/news/${post.slug}/`

    } else {
      return ""
    }
  },

  productsPath() {
    return `/produtos/`
  },

  productCategoryPath(slug) {
    return `/produtos/${slug}/`
  },

  productItemPath(post) {
    if (post.primary_categoria_produtos) {
      const categorySlug = post.primary_categoria_produtos.slug
      return `/produtos/${categorySlug}/${post.slug}/`
    } else {
      return ""
    }
  }
}
