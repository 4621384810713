import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import router from "../domain/router"

const defaultMeta = [
  { name: "theme-color", content: "#ffffff" },
  { name: "google-site-verification", content: "RKIrW48QBLDrGojoljI1q7OI88HIPxwccnEd1N-UNJU" },
]

function SEO({ lang, meta, title, yoast_json_ld, post, term, author }) {
  const { wordpressYoast } = useStaticQuery(
    graphql`
      query {
        wordpressYoast(name: {eq: "home"}) {
          yoast_json_ld
          yoast_title
          yoast_meta {
            content
            name
            property
          }
        }
      }
    `
  )

  if (title) {
    title = `${title} - ${wordpressYoast.yoast_title}`
  } else if (author) {
    title = `${author.name} - ${wordpressYoast.yoast_title}`
  } else if (post) {
    title = post.yoast_title
  } else if (term) {
    title = term.yoast_title
  } else {
    title = wordpressYoast.yoast_title
  }

  if (post) {
    meta = (post.yoast_meta || []).concat(meta)
  } else if (term) {
    meta = (term.yoast_meta || []).concat(meta)
  } else {
    meta = (wordpressYoast.yoast_meta || []).concat(meta)
  }

  meta = meta.concat(defaultMeta)
  meta = meta.map(item => ({ ...item, content: router.replaceUrl(item.content) }))

  if (!yoast_json_ld) {
    if (post) {
      yoast_json_ld = post.yoast_json_ld
    } else if (term) {
      yoast_json_ld = term.yoast_json_ld
    } else {
      yoast_json_ld = wordpressYoast.yoast_json_ld
    }
  }

  yoast_json_ld = router.replaceUrl(yoast_json_ld)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={meta}
    >
      <link rel="icon" href="/favicon-32x32.png" />
      {/* <link rel="manifest" href="/manifest.webmanifest" /> */}
      <link rel="apple-touch-icon" sizes="48x48" href="/icons/icon-48x48.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/icons/icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="96x96" href="/icons/icon-96x96.png" />
      <link rel="apple-touch-icon" sizes="144x144" href="/icons/icon-144x144.png" />
      <link rel="apple-touch-icon" sizes="192x192" href="/icons/icon-192x192.png" />
      <link rel="apple-touch-icon" sizes="256x256" href="/icons/icon-256x256.png" />
      <link rel="apple-touch-icon" sizes="384x384" href="/icons/icon-384x384.png" />
      <link rel="apple-touch-icon" sizes="512x512" href="/icons/icon-512x512.png" />

      <script type='application/ld+json' className='yoast-schema-graph yoast-schema-graph--main'>{yoast_json_ld}</script>      

      <script>{`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1823239118004380');
        fbq('track', 'PageView');
      `}</script>
      <noscript>{`
        <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1823239118004380&ev=PageView&noscript=1"/>
      `}</noscript>
    </Helmet >
  )
}

SEO.defaultProps = {
  lang: `pt-BR`,
  meta: []
}

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  yoast_json_ld: PropTypes.string,
  post: PropTypes.object,
  term: PropTypes.object,
  author: PropTypes.object,
}

export default SEO
