import React from "react"
import { Link } from "gatsby"

class Navbar extends React.Component {
  constructor(props) {
    super(props)

    this.burgerRef = React.createRef()
    this.navbarMenuRef = React.createRef()
    this.toggleBurger = this.toggleBurger.bind(this)
  }

  toggleBurger() {
    this.burgerRef.current.classList.toggle('is-active')
    this.navbarMenuRef.current.classList.toggle('is-active')
  }

  render() {
    return (
      <nav className="navbar navbar-component container" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src="/images/logo.png" alt="Logo" className="logo-img" />
          </Link>

          <button className="navbar-burger burger" aria-label="menu" aria-expanded="false" onClick={this.toggleBurger} ref={this.burgerRef}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>

        <div className="navbar-menu" ref={this.navbarMenuRef}>
          <div className="navbar-start">
            <Link to="/empresa/" className="navbar-item" activeClassName="is-active">
              <strong>Empresa</strong>
            </Link>

            <Link to="/produtos/" className="navbar-item" activeClassName="is-active">
              <strong>Produtos</strong>
            </Link>

            <Link to="/blog/" className="navbar-item" activeClassName="is-active">
              <strong>Blog e Receitas</strong>
            </Link>

            <Link to="/news/" className="navbar-item" activeClassName="is-active">
              <strong>News</strong>
            </Link>

            <Link to="/fale-conosco/" className="navbar-item" activeClassName="is-active">
              <strong>Fale Conosco</strong>
            </Link>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <Link to="/onde-comprar/" id="menu-superior-onde-comprar" className="button is-primary is-outlined is-rounded" activeClassName="is-active">
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.083" height="18.511" viewBox="0 0 15.083 18.511">
                      <g transform="translate(-4 -1.5)">
                        <g transform="translate(4 1.5)">
                          <path className="primary hover" d="M13.428,7a3.428,3.428,0,1,0,3.428,3.428A3.395,3.395,0,0,0,13.428,7Zm0,5.485a2.057,2.057,0,1,1,2.057-2.057A2.02,2.02,0,0,1,13.428,12.485Z" transform="translate(-5.886 -3.229)" />
                          <path className="primary hover" d="M11.747,1.5h-.206A7.52,7.52,0,0,0,4,9.042c0,5.553,6.033,10.078,7.062,10.764.069.069.137.137.206.137a.514.514,0,0,0,.343.069.974.974,0,0,0,.411-.137c.206-.137,7.062-4.936,7.062-10.832A7.568,7.568,0,0,0,11.747,1.5Zm-.206,17c-1.371-1.028-6.17-5-6.17-9.461A6.16,6.16,0,0,1,11.4,2.871h.137a6.145,6.145,0,0,1,6.17,6.17C17.712,13.5,12.981,17.406,11.542,18.5Z" transform="translate(-4 -1.5)" />
                        </g>
                      </g>
                    </svg>

                  </span>
                  <span><strong>Onde comprar</strong></span>
                </Link>
                <Link to="/quero-vender/" id="menu-superior-quero-vender" className="button is-primary is-outlined is-rounded" activeClassName="is-active">
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.798" height="14.998" viewBox="0 0 17.798 14.998">
                      <g transform="translate(-11.5 -19.5)">
                        <path className="primary-stroke hover" d="M26.931,20H13.866L12,23.733v.933a.933.933,0,0,0,1.866,0,.933.933,0,0,0,1.866,0,.933.933,0,1,0,1.866,0,.933.933,0,0,0,1.866,0,.933.933,0,0,0,1.866,0,.933.933,0,0,0,1.866,0,.933.933,0,0,0,1.866,0,.933.933,0,0,0,1.866,0,.933.933,0,0,0,1.866,0v-.933Z" />
                        <path className="primary-stroke hover" d="M30.465,54.533H30V48H16.933v6.533h-.467a.467.467,0,1,0,0,.933H21.6V51.733a1.866,1.866,0,0,1,3.733,0v3.733h5.133a.467.467,0,1,0,0-.933Z" transform="translate(-3.067 -21.467)" />
                      </g>
                    </svg>
                  </span>
                  <span><strong>Quero vender</strong></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
